import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import { routes, router } from "./router";
import nftDb from "./db/nft";
import { createHead } from "@vueuse/head";
import { Dropdown, Button, Sidebar, Modal } from "@oruga-ui/oruga-next";
import "@oruga-ui/oruga-next/dist/oruga.css";
import "atropos/atropos.min.css";
import { Vue3Mq } from "vue3-mq";

createApp(App)
  .use(router)
  .use(Dropdown)
  .use(Button)
  .use(Sidebar)
  .use(Modal)
  .use(createHead())
  .use(Vue3Mq, {
    preset: "tailwind",
  })
  .provide("nftDb", nftDb.reverse())
  .provide("routes", routes)
  .mount("#app");
