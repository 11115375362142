const nft = [
  {
    id: "Lleron-804",
    name: "Lleron 804",
    fileExt: "webp",
    viewLink:
      "https://rarible.com/token/0xf6793da657495ffeff9ee6350824910abc21356c:76624650545986820208738071732026034892922489849664437761167600692224138936321?tab=details",
    linkType: "rarible",
  },
  {
    id: "Neshan-IOD6",
    name: "Neshan IOD6",
    fileExt: "jpg",
    viewLink:
      "https://rarible.com/token/0xf6793da657495ffeff9ee6350824910abc21356c:76624650545986820208738071732026034892922489849664437761167600692224138936324?tab=details",
    linkType: "rarible",
  },
  {
    id: "Sephora",
    name: "Sephora",
    fileExt: "jpg",
    viewLink:
      "https://rarible.com/token/0xf6793da657495ffeff9ee6350824910abc21356c:76624650545986820208738071732026034892922489849664437761167600692224138936326",
    linkType: "rarible",
  },
  {
    id: "Frozen-state",
    name: "Frozen state",
    fileExt: "jpg",
    viewLink:
      "https://rarible.com/token/0xF6793dA657495ffeFF9Ee6350824910Abc21356C:76624650545986820208738071732026034892922489849664437761167600692224138936329",
    linkType: "rarible",
  },
  {
    id: "Sienides",
    name: "Sienides",
    fileExt: "jpg",
    viewLink:
      "https://rarible.com/token/0xF6793dA657495ffeFF9Ee6350824910Abc21356C:76624650545986820208738071732026034892922489849664437761167600692224138936327",
    linkType: "rarible",
  },
  {
    id: "The-Wrathful-Hollow",
    name: "The wrathful hollow",
    fileExt: "jpg",
    viewLink:
      "https://rarible.com/token/0xF6793dA657495ffeFF9Ee6350824910Abc21356C:76624650545986820208738071732026034892922489849664437761167600692224138936328",
    linkType: "rarible",
  },
  {
    id: "Consequence",
    name: "Consequence",
    fileExt: "jpg",
    viewLink:
      "https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/76624650545986820208738071732026034892922489849664437761167600707617301725185",
    linkType: "opensea",
  },
  {
    id: "Moment-Of-Reflection",
    name: "Moment Of Reflection",
    fileExt: "jpg",
    viewLink:
      "https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/76624650545986820208738071732026034892922489849664437761167600708716813352961",
    linkType: "opensea",
  },
  {
    id: "Impact",
    name: "Impact",
    fileExt: "jpg",
    viewLink:
      "https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/76624650545986820208738071732026034892922489849664437761167600709816324980737",
    linkType: "opensea",
  },
  {
    id: "Night-Resonance",
    name: "Night-Resonance",
    fileExt: "jpg",
    viewLink:
      "https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/76624650545986820208738071732026034892922489849664437761167600710915836608513",
    linkType: "opensea",
  },
  {
    id: "Wild",
    name: "Wild",
    fileExt: "jpg",
    viewLink:
      "https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/76624650545986820208738071732026034892922489849664437761167600712015348236289",
    linkType: "opensea",
  },
  {
    id: "Hidden-Eyes",
    name: "Hidden-Eyes",
    fileExt: "jpg",
    viewLink:
      "https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/76624650545986820208738071732026034892922489849664437761167600713114859864065",
    linkType: "opensea",
  },
  {
    id: "Dreamshadows",
    name: "Dreamshadows",
    fileExt: "jpg",
    viewLink:
      "https://hicetnunc.art/objkt/581227",
    linkType: "hen",
  },
  {
    id: "Mixed-Feelings",
    name: "Mixed-Feelings",
    fileExt: "jpg",
    viewLink:
      "https://hicetnunc.art/objkt/579752",
    linkType: "hen",
  },
  {
    id: "Electric-Tones",
    name: "Electric-Tones",
    fileExt: "jpg",
    viewLink:
      "https://foundation.app/@black.cat/~/120985",
    linkType: "foundation",
  },
];

export default nft;
